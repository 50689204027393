import React from 'react'
import { Button, Col, Container, Image, Row } from 'react-bootstrap'
import Footer from './Footer'
import Navigation from './Navigation'
import Lottie from 'react-lottie';
import * as animationData from '../Images/118785-responsive-website-design.json'
import * as animationData2 from '../Images/125363-application.json'
import bgimaGE from '../Images/bg-art-6.png'
import '../Component.css/About.css'
import { Link } from 'react-router-dom';
import anilsir from '../Images/Team/sir.jpeg'
import shri from '../Images/Team/shri2.jpeg'
import arvind from '../Images/Team/arvind.jpeg'
import pavan from '../Images/Team/pawan.jpeg'
import rohit from '../Images/Team/rohit.jpg'
import kuldeep from '../Images/Team/kuldeep.jpg'
import akshay from '../Images/Team/akshay.jpg'
import mayank from '../Images/Team/mayank.jpg'
import satyam from '../Images/Team/satyam.jpg'
import nitesh from '../Images/Team/nitesh.JPG'



export default function About() {

    {
        window.scrollTo(0, 0)
    }

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };
    const defaultOptions2 = {
        loop: true,
        autoplay: true,
        animationData: animationData2,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <>
            {/* <Navigation /> */}
            <Container style={{ backgroundColor: '#050622', padding: '30px' }} fluid>
                <div className='aboutbg'></div>
                <div className='aboutbg2  d-none d-lg-block'></div>
                <div className='d-none d-lg-block' style={{ height: '350px', width: '300px', position: 'absolute', marginLeft: '70%' }}>

                    <Image src={bgimaGE} style={{ height: '100%', width: '100%' }}></Image>
                </div>
                <div className='abouttxt'>
                    <h3 style={{ color: '#ffffff', textAlign: 'center', letterSpacing: '3px' }}>WHO <span style={{ color: '#feb302', fontWeight: 900 }}>WE ARE</span></h3>
                    <p style={{ color: '#ffffff', textAlign: 'center', letterSpacing: '1px', margin: '20px 50px 5px 50px' }}>Softseekers is a Website, App And Software development company specializing in providing businesses worldwide with custom technology solutions. As a mobile and web-based application development company with skilled software developers, Softseekers combines technological expertise, specific domain experience, and a passion for excellence to deliver enterprise-grade solutions.</p>
                    <p style={{ color: '#ffffff', textAlign: 'center', letterSpacing: '1px', margin: '20px 50px 5px 50px' }}>We develope mobile apps for a range of top platforms, including Android and iOS. We also have extensive knowledge in developing complex web backend and frontend projects. Our primary passion is turning your ideas into game-changing products across global markets. Our team offers a complete range of web & mobile application services, including initial prototyping, UI/UX design, Android / iOS mobile development, as well as application backend and website infrastructure.</p>

                    <Row>
                        <Col md={6} style={{ marginTop: '40px' }}>
                            <Lottie options={defaultOptions}
                                height={350}
                                width={350}
                            />
                        </Col>
                        <Col md={6} style={{ marginTop: '100px' }}>
                            <h3 style={{ color: '#ffffff', letterSpacing: '3px' }}>OUR <span style={{ color: '#feb302', fontWeight: 900 }}>VISION</span></h3>
                            <p style={{ color: '#ffffff', marginRight: '4px' }}>
                                Our IT company envisions a future where innovation propels businesses to unprecedented heights. At the core of our vision is a commitment to pioneering technological solutions that empower organizations. We strive to be the vanguard of digital transformation, offering cutting-edge innovations that drive efficiency, productivity, and sustained growth. Anticipating the evolving needs of our clients, we not only provide solutions for today but also chart a course for future challenges. Customer satisfaction is paramount in our vision; we aim not only to meet but to exceed expectations. Through tailored, reliable, and innovative services, we seek to forge enduring partnerships, positioning ourselves as the trusted ally for businesses navigating the dynamic landscape of technology. Join us on this journey as we shape a future where our IT expertise is synonymous with progress, resilience, and the success of our clients.</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} style={{ marginTop: '100px' }}>
                            <h3 style={{ color: '#ffffff', letterSpacing: '3px', marginLeft: '40px' }}>OUR <span style={{ color: '#feb302', fontWeight: 900 }}>MISSION</span></h3>
                            <p style={{ color: '#ffffff', marginLeft: '40px' }}>Our mission is to meticulously craft and deliver customized IT solutions and software to our clients, ensuring a bespoke experience that aligns seamlessly with their unique needs. We are dedicated to not only meeting but exceeding customer expectations, prioritizing a rich and gratifying experience for every client we serve. Concurrently, we are committed to fostering the growth and financial security of our employees, recognizing their invaluable contributions to our collective success. By striking this balance between client satisfaction and employee well-being, our mission is to establish ourselves as a trusted partner in the dynamic realm of IT, where innovation and personalized service converge to drive success for both our clients and our dedicated team.</p>
                        </Col>
                        <Col md={6} style={{ marginTop: '40px' }}>
                            <div className='mx-auto d-block' style={{ height: '350px', width: '300px', position: 'absolute' }}>

                                <Image src={bgimaGE} style={{ height: '100%', width: '100%' }}></Image>
                            </div>
                            <Lottie options={defaultOptions2}
                                height={350}
                                width={350}
                                style={{ position: 'relative' }}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <h3 style={{ marginTop: '50px', color: '#fff', letterSpacing: '3px', textAlign: 'center', fontWeight: 800 }}>CREATE YOUR NEXT PROJECT<br></br> WITH OUR <span style={{ color: '#feb302', fontWeight: 900 }}>EXPERT TEAM</span></h3>
                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/dr-anil-kumar-kashyap-1681321b/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={anilsir} style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Dr. Anil Kashyap</h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px' }}>( Business Mentor )</p>
                            </a>
                        </Col>
                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/shrikant-dwivedi-113a06172/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={shri} className='mx-auto d-block' style={{ height: '100%', width: '90%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Shrikant Dwivedi</h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px' }}>( Co-Founder & CEO )</p>
                            </a>
                        </Col>
                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/arvind-vishwakarma-573193181/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={arvind} style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Arvind Vishwakarma</h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px' }}>( Full-Stack Developer )</p>
                            </a>
                        </Col>
                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/pavan-kumar-b5b2ba206/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={pavan} style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Pavan Ahirwar</h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px' }}>( Sr. Android & iOS developers )</p>
                            </a>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/pavan-kumar-b5b2ba206/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={rohit} style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Rohit Kewat</h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px' }}>( Sr. Full Stack Developer )</p>
                            </a>
                        </Col>
                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/pavan-kumar-b5b2ba206/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={mayank} style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Mayank Kankoriya </h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px' }}>( Marketing Head)</p>
                            </a>
                        </Col>
                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/pavan-kumar-b5b2ba206/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={akshay} style={{ height: '100%', width: '100%', objectFit: "cover" }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Akshay Dangi</h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px' }}>( Full Stack Developer )</p>
                            </a>
                        </Col>

                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/pavan-kumar-b5b2ba206/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={satyam} style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Satyam Kaurav</h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px', objectFit: "cover" }}>( Android & iOS developers
                                    )</p>
                            </a>
                        </Col>

                    </Row>

                    <Row>
                        <Col md={3} style={{ padding: '20px' }}>
                            <a href='https://www.linkedin.com/in/nitesh-nagar-76-h/' target='_blank' style={{ textDecoration: "none" }}>
                                <div className='mx-auto d-block' style={{ marginTop: '40px', height: '220px', width: '200px' }}>
                                    <Image src={nitesh} style={{ height: '100%', width: '100%' }}></Image>
                                </div>
                                <h5 style={{ marginTop: '10px', textAlign: 'center', color: '#FFF', fontSize: '17px' }}>Nitesh Nager</h5>
                                <p style={{ color: '#b4b4b4', textAlign: 'center', fontSize: '13px' }}>( Full Stack Developer )</p>
                            </a>
                        </Col>
                    </Row>

                    <Link to='/letsTalk' style={{ textDecoration: "none" }}>
                        <Button className='mx-auto d-block' style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '30px', border: 'none', marginTop: '20px', fontSize: '13px', fontFamily: 'DM Sans, sans-serif' }} >Get Started</Button>
                    </Link>
                </div>
                <Footer />
            </Container>
        </>
    )
}

