import React from 'react'
import { Button, Carousel, Col, Container, Image, Row } from 'react-bootstrap'
import bgimage from '../Images/322099774_451148903900492_969058150251871046_n.jpg'
import testimg from '../Images/testimonials-1.jpg'
import testimg1 from '../Images/testimonials-2.jpg'
import testimg2 from '../Images/testimonials-3.jpg'
import Tilt from 'react-parallax-tilt';
import pdfbroucher from '../Images/ssibrochurefinal.pdf'

export default function Testimonials() {
    return (
        <>
            <Container style={{ marginTop: '50px' }} id="testId">
                <Row>
                    <Col md={6}>
                        <h6 style={{ color: '#feb302', marginTop: '20px' }}>TESTIMONIALS</h6>
                        <h1 style={{ color: '#ffffff', fontWeight: 900 }}>Some <span style={{ color: 'rgb(231,47,235)' }}>Sweet Talk</span> Of Our Happy Clients</h1>
                        <p style={{ color: '#ffffff', marginTop: '30px' }}>Our team run various tests depending on the project specifics and ensures that the developed code is bug-free and the app will be enjoyed by its users.</p>
                        <p style={{ color: '#ffffff', marginTop: '30px' }}>We have an excellent team of web development experts who are constantly experimenting and implementing innovative ideas that will transform website designs into a gold mine for your business.</p>
                        <a href={pdfbroucher}>
                        <Button style={{ background: 'linear-gradient(90deg,#3dc1d3  0%, #33d9b2 100%)', borderRadius: '10px', border: 'none', marginTop: '10px', fontSize: '16px', fontFamily: 'DM Sans, sans-serif', color: '#000000', fontWeight: 700 }} >Download Our Broucher</Button>
                        </a>
                    </Col>
                    <Col md={6}>
                    <Row>
                        <Col>
                        <Tilt>

                            <Image className='mx-auto d-block' src={testimg} style={{marginTop: '30px', height: '110px', width: '110px', borderRadius: '50%' }} />
                        </Tilt>

                        </Col>
                        <Col>
                            <p style={{ marginTop: '30px',color: '#ffffff', fontSize: '14px' }}>Always reliable and fast, I can always count on you guys to get requests done. Thank you!</p>
                            <p style={{ color: '#ffffff', fontSize: '14px' ,fontWeight:700}}>Bharat Phrama</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Tilt>

                            <Image className='mx-auto d-block' src={testimg1} style={{marginTop: '30px', height: '110px', width: '110px', borderRadius: '50%' }} />
                        </Tilt>

                        </Col>
                        <Col>
                            <p style={{ marginTop: '30px',color: '#ffffff', fontSize: '14px' }}>Excellent service. Quick response, efficient troubleshooting and resolution with full explanation of the problem.</p>
                            <p style={{ color: '#ffffff', fontSize: '14px' ,fontWeight:700}}>Screenwork Institute of Animation</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                        <Tilt>
                            
                            <Image className='mx-auto d-block' src={testimg2} style={{marginTop: '30px', height: '110px', width: '110px', borderRadius: '50%' }} />
                        </Tilt>

                        </Col>
                        <Col>
                            <p style={{ marginTop: '30px',color: '#ffffff', fontSize: '14px' }}>The team was courteous, prompt and professional. SSI's engineers always deliver.</p>
                            <p style={{ color: '#ffffff', fontSize: '14px' ,fontWeight:700}}>B+Harp</p>
                        </Col>
                    </Row>
                    
                       
                    </Col>
                </Row>




                {/* <h5 style={{ color: '#fff', textAlign: 'center' }}>Testimonials</h5>
                <hr className='mx-auto d-block' style={{ color: '#fff' }}></hr>
                <Row>
                    <Col md={12}>
                        <Carousel>
                            <Carousel.Item style={{padding:'20px'}}>
                            <div className='mx-auto d-block' style={{height:'100px',width:'100px',borderRadius:50}}>
                               <Image src={bgimage} style={{height:'100%',width:'100%',borderRadius:'50%'}}></Image>
                            </div>
                               <h6 style={{marginTop:'50px',textAlign:'center',color:'#fff'}}>Arvind Vishwakarma</h6>
                               <p style={{margin:'20px 50px 50px 50px',textAlign:'center',color:'#fff',fontSize:'13px'}}>“I’ve missed more than 9000 shots in my career I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life. And that is why I succeed.” —Michael Jordan</p>
                            </Carousel.Item>
                            <Carousel.Item style={{padding:'20px'}}>
                            <div className='mx-auto d-block' style={{height:'100px',width:'100px',borderRadius:50}}>
                               <Image src={bgimage} style={{height:'100%',width:'100%',borderRadius:'50%'}}></Image>
                            </div>
                               <h6 style={{marginTop:'50px',textAlign:'center',color:'#fff'}}>Arvind Vishwakarma</h6>
                               <p style={{margin:'20px 50px 50px 50px',textAlign:'center',color:'#fff',fontSize:'13px'}}>“I’ve missed more than 9000 shots in my career I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life. And that is why I succeed.” —Michael Jordan</p>
                            </Carousel.Item>
                            <Carousel.Item style={{padding:'20px'}}>
                            <div className='mx-auto d-block' style={{height:'100px',width:'100px',borderRadius:50}}>
                               <Image src={bgimage} style={{height:'100%',width:'100%',borderRadius:'50%'}}></Image>
                            </div>
                               <h6 style={{marginTop:'50px',textAlign:'center',color:'#fff'}}>Arvind Vishwakarma</h6>
                               <p style={{margin:'20px 50px 50px 50px',textAlign:'center',color:'#fff',fontSize:'13px'}}>“I’ve missed more than 9000 shots in my career I’ve lost almost 300 games. 26 times I’ve been trusted to take the game winning shot and missed. I’ve failed over and over and over again in my life. And that is why I succeed.” —Michael Jordan</p>
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row> */}
            </Container>
        </>
    )
}
